<template>
  <v-container class="dashboard" fluid>
    <div class="mainTitle">Dashboard</div>
    <!-- This Is The Original Code For The Structure Of HTML element -->
    <!--  <div class="d-flex align-end ma-5">
      <v-row class="ml-0">
        <v-col class="pr-0 pl-0 ma-0" cols="1">
          <v-btn width="100%" @click="reload">
            <v-icon color="textColor">mdi-reload</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3" class="pr-0 pl-0 ml-3 inputField">
          <v-text-field
            dense
            color="drawerHover"
            type="number"
            class="text-green"
            min="2000"
            background-color="white"
            solo
            flat
            item-color="drawerHover"
            v-model="year"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="pl-0">
          <v-select
            v-model="month"
            text-color="red"
            color="drawerHover"
            background-color="white"
            flat
            dense
            solo
            offset-y
            item-color="drawerHover"
            transition="scale-transition"
            :items="months"
            item-text="month"
            item-value="num"
          />
        </v-col>
      </v-row>
    </div> -->

    <div class="filterBtn">
      <v-btn @click="reload" class="reloadBtn">
        <v-icon color="textColor">mdi-reload</v-icon>
      </v-btn>
      <div class="d-flex-column justify-end">
        <custom-label item-field-name="Timeline" class="dashBoardLabel" />
        <v-select
          dense
          color="drawerHover"
          v-model="year"
          background-color="white"
          flat
          solo
          offset-y
          item-color="drawerHover"
          :items="years"
          item-text="year"
          hide-details
        />
      </div>

      <v-select
        class="selector white--text"
        v-model="month"
        background-color="white"
        flat
        dense
        solo
        offset-y
        item-color="drawerHover"
        transition="scale-transition"
        :items="months"
        item-text="month"
        item-value="num"
        :menu-props="{ contentClass: 'selectorForMonth', top: false, offsetY: true }"
        hide-details
      />
    </div>

    <canvas
      class="chart"
      style="position: relative; height: 30vh; width: 80vw"
      id="myChart"
    ></canvas>
    <canvas
      class="chart"
      style="position: relative; height: 30vh; width: 80vw"
      id="outChart"
    ></canvas>
    <canvas
      class="chart"
      style="position: relative; height: 30vh; width: 80vw"
      id="totalCallChart"
    ></canvas>
  </v-container>
</template>
<script>
import { msgSentStatistics } from '@/api/admin';
import api from '@/api';
import moment from 'moment';
import Chart from 'chart.js/auto';
import CustomLabel from '../../components/customFormLabel/customLabel';

export default {
  name: 'dashboard',
  name: 'ActivityDialog',
  components: { CustomLabel },
  data() {
    return {
      year: null,
      month: null,
      data: [],
      messageInChart: null,
      messageOutChart: null,
      totalCalls: null,
      months: [],
      years: [],
    };
  },
  watch: {
    filter: {
      immediate: true,
      async handler() {
        await this.reload();
        await this.updateChart();
      },
    },
  },
  computed: {
    filter() {
      return JSON.stringify([this.year, this.month]);
    },
  },
  async mounted() {
    this.month = moment().month() + 1;
    /*     this.months = [
      { month: 'January', num: 1 },
      { month: 'February', num: 2 },
      { month: 'March', num: 3 },
      { month: 'April', num: 4 },
      { month: 'May', num: 5 },
      { month: 'June', num: 6 },
      { month: 'July', num: 7 },
      { month: 'August', num: 8 },
      { month: 'September', num: 9 },
      { month: 'October', num: 10 },
      { month: 'November', num: 11 },
      { month: 'December', num: 12 },
    ]; */
    this.months = [
      { month: 'Jan', num: 1 },
      { month: 'Feb', num: 2 },
      { month: 'Mar', num: 3 },
      { month: 'Apr', num: 4 },
      { month: 'May', num: 5 },
      { month: 'Jun', num: 6 },
      { month: 'Jul', num: 7 },
      { month: 'Aug', num: 8 },
      { month: 'Sep', num: 9 },
      { month: 'Oct', num: 10 },
      { month: 'Nov', num: 11 },
      { month: 'Dec', num: 12 },
    ];
    this.year = moment().year();
    this.yearsFunction();
    /*     this.years = [
      { year: moment().year() },
      { year: moment().year() - 1 },
      { year: moment().year() - 2 },
      { year: moment().year() - 3 },
      { year: moment().year() - 4 },
      { year: moment().year() - 5 },
      { year: moment().year() - 6 },
      { year: moment().year() - 7 },
      { year: moment().year() - 8 },
      { year: moment().year() - 9 },
      { year: moment().year() - 10 },
      { year: moment().year() - 11 },
      { year: moment().year() - 12 },
      { year: moment().year() - 13 },
      { year: moment().year() - 14 },
      { year: moment().year() - 15 },
      { year: moment().year() - 16 },
      { year: moment().year() - 17 },
      { year: moment().year() - 18 },
      { year: moment().year() - 19 },
      { year: moment().year() - 20 },
    ]; */

    await this.reload();
    this.messageInChart = this.initChart(
      'myChart',
      0.5,
      '#54BAE7',
      1,
      '#01CFA2',
      'Total Received Message Report',
      'in',
    );

    this.messageOutChart = this.initChart(
      'outChart',
      0.5,
      '#F5BF50',
      1,
      '#F85D2A',
      'TTotal Sent Message Report',
      'out',
    );
    this.totalCalls = this.initChart(
      'totalCallChart',
      0,
      'pink',
      1,
      'pink',
      'Total Call Minutes Report',
      'total',
    );
  },
  methods: {
    async reload() {
      let filter;
      if (this.year) {
        filter = Object.assign({}, { year: parseInt(this.year) }, filter);
      }
      if (this.month) {
        filter = Object.assign({}, { month: this.month }, filter);
      }
      try {
        this.data = (await msgSentStatistics(api, { filter })).data;
      } catch (e) {}
    },
    yearsFunction() {
      for (var i = 0; i < 21; i++) {
        this.years.push(moment().year() - i);
      }
    },

    initChart(IdName, startlevel, startColor, endLevel, endColor, title, dataType) {
      const ctx = document.getElementById(IdName);
      const bar_ctx = document.getElementById(IdName).getContext('2d');
      var background_1 = bar_ctx.createLinearGradient(0, 0, 0, 300);

      background_1.addColorStop(startlevel, startColor);
      background_1.addColorStop(endLevel, endColor);

      return new Chart(ctx, {
        type: 'bar', //bar, horizontalBar, pie, line, dougnout
        data: {
          datasets: [
            {
              backgroundColor: background_1,
              data: this.data,
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
              font: {
                weight: 'bold',
              },
            },
            legend: {
              display: false,
            },
          },
          parsing: {
            xAxisKey: 'team.label',
            yAxisKey: dataType,
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
      });
    },

    async updateChart() {
      if (this.totalCalls && this.messageOutChart && this.messageInChart != null) {
        this.messageInChart.data.datasets[0].data = this.data;
        this.messageOutChart.data.datasets[0].data = this.data;
        this.totalCalls.data.datasets[0].data = this.data;
        this.totalCalls.update();
        this.messageOutChart.update();
        this.messageInChart.update();
      }
    },
  },
};
</script>
<style lang="scss">
@import '@/style/custom.scss';
.filterBtn .v-input input {
  color: var(--v-textColor-base) !important;
}

.filterBtn .v-select.v-input--dense .v-select__selection--comma {
  color: var(--v-textColor-base);
}
.filterBtn .v-select.v-input--dense .v-select__selection--comma {
  color: var(--v-textColor-base);
}
.chart {
  border: 5px solid var(--v-borderColor-base);
  border-radius: 20px;
  margin: 20px;
  background-color: white;
}

.filterBtn {
  margin: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.filterBtn > * {
  margin-right: 12px !important;
}
.filterBtn
  .v-input.text-green.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--solo-flat.v-text-field--is-booted.v-text-field--enclosed {
  flex: 0 0 auto !important;
}
.filterBtn .theme--light.v-icon {
  color: var(--v-textColor-base) !important;
}
.filterBtn .v-application .primary--text {
  color: var(--v-textColor-base) !important;
}
.filterBtn
  .v-input.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--solo-flat.v-text-field--is-booted.v-text-field--enclosed.v-select {
  flex: 0 0 auto;
}
.v-menu__content
  .v-list
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-textColor-base) !important;
}

.dashboard .filterBtn i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  font-size: 40px;
}

.reloadBtn {
  height: 40px !important;
}
.dashBoardLabel .itemFieldName {
  color: var(--v-textColor-base) !important;
}
.selectorForMonth > * {
  display: grid;
  grid-template-columns: 50px 50px 50px 50px;
  padding: 16px;
  padding-bottom: 4px;
  padding-right: 4px;
}
.selectorForMonth > * > * {
  @include menuButtonLook();
  padding: 0;
  border-radius: 4px;
  margin-bottom: 12px;
  margin-right: 12px;
}
.selectorForMonth > * > *:not(:last-child) {
}

.selectorForMonth .v-list-item__title {
  color: white !important;
  display: flex;
  justify-content: center;
}

.selectorForMonth .v-list .v-list-item--active {
  @include selectedColor();
}

// .dashboard.v-menu__content ::v-deep {
//   color: black;
// }
</style>
