<template>
  <div id="identity">
    <ListView
      title="Profiles"
      @reload="reload"
      @create="actionCreate"
      :search.sync="search"
      :data="data"
      :header="header"
      @navDetails="actionDetails"
      :page.sync="page"
      :enableCreate="selectedRole.toLowerCase() !== 'client'"
    >
      <template v-slot:filter>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
          :nudge-width="200"
          max-width="400"
          class="pa-4 identityMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="menuDropDown d-flex justify-space-between">
              <v-icon color="borderColor">mdi-filter</v-icon>
              <div class="grey--text">Filter</div>
              <v-icon color="borderColor" large>mdi-menu-down</v-icon>
            </div>
          </template>
          <v-card class="pa-4 identityCardButton">
            <v-card-title class="pa-0">Status Label</v-card-title>
            <v-row class="pb-6 mr-0">
              <v-col cols="4" v-for="item in statusLabel" class="clearPadding pr-0 pb-0">
                <v-btn
                  @click="actionClickLabel(item)"
                  :min-width="0"
                  :class="[selectedLabel.includes(item) ? 'btnFilter' : '', 'selectorButton']"
                >
                  {{ item }}
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-card-title class="pl-0 pb-2">Date of Register</v-card-title>

            <v-row class="mr-0">
              <v-col cols="4" v-for="item in registerYear" class="clearPadding pr-0 pb-0">
                <v-btn
                  @click="actionClickYear(item)"
                  :min-width="0"
                  :class="[{ btnFilter: item.enable }, 'selectorButton']"
                >
                  {{ item.year }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pb-6 mr-0">
              <v-col cols="2" v-for="item in registerMonths" class="clearPadding pr-0 pb-0">
                <v-btn
                  @click="actionClickMonth(item)"
                  :min-width="0"
                  :class="[{ btnFilter: item.enable }, 'selectorButton']"
                >
                  {{ item.month }}
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-center resetAndApply">
              <v-btn text @click="resetAllFilter"> RESET</v-btn>
              <v-btn color="primary" text @click="addToFilter"> APPLY </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:showTableBtn>
        <v-btn @click="showTable = !showTable">{{ showTable ? 'Card' : 'Table' }}</v-btn>
      </template>

      <template v-slot:table v-if="showTable">
        <v-data-table
          :items="data.docs"
          :server-items-length="data.pages || 1"
          :items-per-page="limit || 10"
          :disable-sort="true"
          hide-default-footer
          :headers="header"
          :calculate-widths="isFluid"
          @click:row="actionDetails"
          class="text--primary font-weight-regular row-height-50"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment('MMM Do YY') }}
          </template>
          <!--           <template v-slot:item.status="{ item }">
            <div :style="{ color: getColor(item.status) }" label>
              {{ item.status | status }}
            </div>
          </template>
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="props">
            <slot :name="name" v-bind="props" />
          </template> -->
        </v-data-table>
      </template>
      <template v-slot:card="{ cardContent }" v-else>
        <Card :content="cardContent" class="clickable" @click.native="actionDetails(cardContent)" />
      </template>
      <IdentityEditDialog
        v-model="editDialog"
        :id="editId"
        :title="title"
        @create="reload"
        @update="reload"
        @delete="reload"
      />
    </ListView>
  </div>
</template>

<script>
import api from '@/api';
import listViewPageMixin from '@/services/listViewPageMixin';
import Card from '@/components/Identity/IdentityCard';
import IdentityEditDialog from '@/components/Identity/IdentityEditDialog';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { merge } from 'lodash';
import { getTeamLabel } from '@/api/team';

export default {
  name: 'profiles',
  data() {
    return {
      header: [
        {
          text: 'User Name',
          value: 'name',
          class: 'white--text',
          align: 'center',
          sortable: false,
        },
        { text: 'Phone Number', value: 'phone', align: 'center', class: 'white--text' },
        { text: 'Status Label', value: 'team.label', align: 'center', class: 'white--text' },
        { text: 'Date of Register', value: 'created_at', align: 'center', class: 'white--text' },
      ],
      registerYear: [
        { year: moment().year() - 2, enable: false },
        { year: moment().year() - 1, enable: false },
        { year: moment().year(), enable: true },
      ],
      selectedLabel: [],
      selectedYear: String(moment().year()),
      addToFilterLabel: [],
      selectedMonthListFilterQuery: [],
      statusLabel: ['Team1', 'Team2', 'Team3', 'Team4', 'Team5', 'Team6', 'Team7', 'Team8'],
      registerMonths: [
        { month: 'Jan', num: '0', enable: false },
        { month: 'Feb', num: '1', enable: false },
        { month: 'Mar', num: '2', enable: false },
        { month: 'Apr', num: '3', enable: false },
        { month: 'May', num: '4', enable: false },
        { month: 'Jun', num: '5', enable: false },
        { month: 'Jul', num: '6', enable: false },
        { month: 'Aug', num: '7', enable: false },
        { month: 'Sep', num: '8', enable: false },
        { month: 'Oct', num: '9', enable: false },
        { month: 'Nov', num: '10', enable: false },
        { month: 'Dec', num: '11', enable: false },
      ],
      menu: false,
      showTable: false,
    };
  },
  props: {
    selectedRole: {},
  },
  mixins: [listViewPageMixin],
  components: { IdentityEditDialog, Card },
  mounted() {},
  computed: {
    ...mapGetters('auth', ['role']),
    restfulURL: () => 'im/admin/Profiles',
    filter() {
      const a = this.selectedRole ? { role: this.selectedRole.toLowerCase() } : null;
      const b =
        this.selectedMonthListFilterQuery.length <= 0
          ? {}
          : {
              $or: this.selectedMonthListFilterQuery,
            };
      const c = {};
      console.log({ c });
      return merge({}, a, b, c);
      /*       return merge({}, a); */
    },

    mergeParams() {
      return {
        populate: ['team'],
      };
    },
  },
  /*   async beforeMount() {
    await this.getStatusLabel();
  }, */
  methods: {
    actionClickLabel(selectedItem) {
      if (this.selectedLabel.includes(selectedItem)) {
        this.selectedLabel.splice(this.selectedLabel.indexOf(selectedItem), 1);
      } else {
        this.selectedLabel.push(selectedItem);
      }
    },
    actionClickYear(selectedItem) {
      for (const element of this.registerYear) {
        element.enable = false;
      }
      this.selectedYear = String(selectedItem.year);
      if (this.selectedYear === String(selectedItem.year)) {
        selectedItem.enable = true;
      }
    },
    actionClickMonth(selectedItem) {
      selectedItem.enable = !selectedItem.enable;
    },
    addToFilter() {
      this.selectedMonthListFilterQuery = [];
      const result = this.registerMonths.filter(month => month.enable === true);
      for (var i = 0; i < result.length; i++) {
        this.selectedMonthListFilterQuery.push({
          created_at: {
            $gte: moment(this.selectedYear)
              .startOf('years')
              .add(result[i].num, 'months')
              .startOf('month')
              .toDate(),
            $lte: moment(this.selectedYear)
              .startOf('years')
              .add(result[i].num, 'months')
              .endOf('month')
              .toDate(),
          },
        });
      }
      this.addToFilterLabel = [...this.selectedLabel];
      this.menu = false;
    },

    resetAllFilter() {
      this.registerMonths.map(month => (month.enable = false));
      this.selectedLabel = [];
      this.menu = false;
    },

    async getStatusLabel() {
      try {
        this.statusLabel = await getTeamLabel(api, this.selectedRole);
        console.log(this.statusLabel);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.selectedItem ::v-deep .v-select__selection--comma {
  margin-bottom: -25px;
}
.role {
  top: 10px;
  position: absolute;
  left: 24px;
  color: var(--v-success-base);
}

#identity {
  ::v-deep .v-data-table {
    border: 6px solid var(--v-borderColor-base);

    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    font-size: 20px;
  }
  ::v-deep .v-data-table-header {
    background-image: linear-gradient(to right, rgb(58, 196, 251) 25%, rgb(3, 139, 249));
    font-size: 2rem !important;
    text-align: center;
  }

  .v-data-table::v-deep th {
    font-size: 20px !important;
  }
  .v-data-table::v-deep td {
    font-size: 12px !important;
  }

  ::v-deep .v-data-table.row-height-50 td {
    height: 30px;
  }
  ::v-deep td {
    border: 1px solid rgb(55, 158, 250);
  }
}
</style>
<style lang="scss" scoped>
@import '@/style/custom.scss';
.menuDropDown {
  background-color: white;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}

/* .identityCardButton {
  .clearPadding:not(:last-of-type) {
    padding-right: 0px;
  }
} */

/* @mixin menuButtonLook {
  background-color: var(--v-filterButtonColor-base) !important;
  width: 100%;
  color: white !important;
} */
.selectorButton {
  width: 100%;
  @include menuButtonLook();
}
.btnFilter {
  @include selectedColor;
}
.resetAndApply > * {
  border: 2px solid var(--v-borderColor-base);
  color: var(--v-textColor-base) !important;
}
#identity {
  ::v-deep .v-input__slot {
    width: 200px !important;
    min-height: 40px !important;
    padding-bottom: 0 !important;
  }
}
</style>

<!-- <style lang="scss">
@mixin buttonColor {
  background-color: blue !important;
}
#identity {
  button {
    @include buttonColor();
  }
}
</style> -->
