<template>
  <v-dialog
    v-model="valueProxy"
    width="60%"
    max-width="100vw"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <ValidationObserver ref="form" v-slot="{ valid }">
      <v-card min-height="250">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px">
            {{ title || 'Profile' }}
          </span>
          <v-spacer />
          <v-btn icon @click="valueProxy = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider v-slot="{ errors }" name="username" rules="required">
                  <custom-label item-field-name="Username" />
                  <v-text-field
                    outlined
                    v-model="model.username"
                    @input="changeUsernameFormat"
                    label="Username"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Name" />
                <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                  <v-text-field
                    outlined
                    v-model="model.name"
                    label="Name"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Email" />
                <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                  <v-text-field
                    outlined
                    v-model="model.email"
                    label="Email"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Password" />
                <ValidationProvider v-slot="{ errors }" name="Password" rules="required">
                  <v-text-field
                    outlined
                    v-model="model.pw"
                    label="Password"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Role" />
                <ValidationProvider v-slot="{ errors }" name="Role" rules="required">
                  <v-select
                    outlined
                    v-model="model.role"
                    label="Role"
                    :items="roleList"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" v-if="isAdmin">
                <custom-label item-field-name="Team" />
                <ValidationProvider v-slot="{ errors }" name="Team" rules="required">
                  <v-select
                    outlined
                    v-model="model.team"
                    label="Team"
                    :items="teamList"
                    :error="!!errors.length"
                    :error-messages="errors"
                    item-value="_id"
                    item-text="label"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <work-period-card v-model="model.workTimePeriod" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="doClose()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="promptCreate()" v-if="isCreate">
            Create
          </v-btn>
          <v-btn color="primary" :disabled="!valid" @click="promptUpdate()" v-if="!isCreate">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import ApiError from '@/api/ApiError';
import editDialogMixin from '@/services/editDialogMixin';
import api from '@/api';
import { createUser } from '@/api/auth/membership';
import { mapActions, mapGetters } from 'vuex';
import CustomLabel from '../customFormLabel/customLabel';
import { emailInvitation } from '@/api/notification';
import { listTeam } from '@/api/team';
import WorkPeriodCard from '@/components/Team/workPeriodCard';

export default {
  name: 'IdentityEditDialog',
  components: { WorkPeriodCard, CustomLabel },
  mixins: [editDialogMixin],
  data() {
    return {
      crudURL: `Profiles${this.id ? '/' + this.id : ''}`,
      roleList: ['Agent', 'Manager'],
      teamList: null,
      model: { workTimePeriod: { timezone: this.timezone } },
    };
  },
  props: {
    // roleList: {
    //   type: Array,
    //   default: () => ['agent', 'manager'],
    // },
    title: {},
  },
  watch: {},
  mounted() {
    this.reload();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'timezone']),
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      this.model = { workTimePeriod: { timezone: this.timezone } };
      if (this.isAdmin) {
        this.teamList = (await listTeam(api)).docs;
      }
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    async promptCreate() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        this.model.role = this.model.role.toLowerCase();
        const model = await createUser(api, this.model);
        await emailInvitation(api, { team: this.model.team, recipients: [model.profile._id] });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Created',
          type: 'success',
          color: 'success',
        });
        this.$emit('create', model);
        return model;
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      // if (ae.code === 'duplicate_key') {
      //   this.$refs.sid.applyResult({
      //     valid: false,
      //     errors: [this.$t('d.sid_was_taken')],
      //   });
      //   return;
      // }
      // alert(ae.message);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
    changeUsernameFormat() {
      this.model.username = this.model.username.toLowerCase().replace(/\s/g, '_');
    },
  },
};
</script>
