<template>
  <v-container>
    <v-card>
      <v-card-title
        ><span class="mainTitle" style="font-size: 20px"> Reset Username </span></v-card-title
      >
      <v-form>
        <ValidationObserver ref="form" v-slot="{ valid }">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="New Username"
                  rules="required"
                  vid="confirm"
                >
                  <v-text-field
                    type="string"
                    label="New Username"
                    outlined
                    v-model="username"
                    @input="val => (username = val.toLowerCase().replace(/\s/g, '_'))"
                    :error="!!errors.length"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Confirm New Username"
                  rules="required|confirmed:confirm"
                >
                  <v-text-field
                    type="string"
                    label="Confirm New Username"
                    name="confirm"
                    outlined
                    v-model="confirmUsername"
                    @input="val => (confirmUsername = val.toLowerCase().replace(/\s/g, '_'))"
                    :error="!!errors.length"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="!valid" color="success" @click="resetUsername">Submit</v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudUpdate } from '@/api/crud';

export default {
  name: 'ChangeUsername',
  props: {
    id: {},
  },
  data() {
    return {
      username: null,
      confirmUsername: null,
    };
  },
  methods: {
    async resetUsername() {
      try {
        await crudUpdate(api, 'users', this.id, { username: this.username });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Your username have been updated',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style></style>
