<template>
  <div class="activityManagement">
    <v-container>
      <v-row>
        <v-col>
          <div class="d-flex">
            <h2 class="mainTitle">{{ title }}</h2>
            <v-spacer />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="flex: 2">
          <v-text-field solo filled prepend-inner-icon="mdi-magnify" v-model="searchVal" />
        </v-col>
      </v-row>

      <template>
        <v-data-table
          :headers="headers"
          :items="list.docs"
          :items-per-page="-1"
          clickable
          hide-default-footer
          @click:row="item => goTo(item.chat)"
          class="text--primary font-weight-regular row-height-50"
        >
          <template v-slot:item.content="{ item }">
            <div label>{{ item.content }} {{ item.chat }}</div>
          </template>
          <template v-slot:item.status="{ item }">
            <div label>
              {{ item.status || 'Empty' }}
            </div>
          </template>
          <template v-slot:item.updated_at="{ item }">
            {{ item.updated_at | moment }}
          </template>
        </v-data-table>
      </template>

      <!--       <div class="addTable">
        <v-row
          no-gutters
          class="justify-center align-center text-center mb-1 itemFieldName"
          style="border-bottom: 1px solid #3ca1fa; height: 60px"
        >
          <v-col cols="8"> Activity Title </v-col>
          <v-col cols="2">Status</v-col>
          <v-col cols="2"> Date </v-col>
        </v-row>
        <v-row
          class="justify-center align-center text-center rowBorder font-weight-regular"
          no-gutters
          v-for="(n, i) in list.docs"
          :key="n._id"
          style="border-bottom: 1px solid #3ca1fa; height: 25px"
        >
          <v-col
            cols="8"
            @click="goTo(n.chat)"
            class="clickable"
            style="border-right: 1px solid #3ca1fa"
            >{{ n.content }}<template v-if="n.isSystem">: {{ n.chat }} </template>
          </v-col>
          <v-col cols="2">{{ n.status || 'Empty' }}</v-col>
          <v-col cols="2">{{ n.updated_at | moment('YYYY-MM-DD HH:mm') }}</v-col>
        </v-row>
      </div> -->
      <v-pagination color="drawerHover" class="mt-4" v-model="pageVal" :length="list.pages || 1" />
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
import api from '@/api';
import { crudList } from '@/api/crud';

export default {
  name: 'ActivityManagement',
  data() {
    return {
      headers: [
        {
          text: 'Activity Title',
          align: 'center',
          sortable: false,
          value: 'content',
          class: 'white--text  ',
        },

        {
          text: 'Status',
          value: 'status',
          align: 'center',
          class: 'white--text  ',
        },
        { text: 'Date', class: 'white--text  ', align: 'center', value: 'updated_at' },
      ],

      title: 'Activity Management',
      list: { docs: [], offset: 0, page: 1, pages: 1, limit: 9 },
      search: '',
      page: 1,
    };
  },
  mounted() {
    this.reload();
  },
  methods: {
    getColor(status) {
      if (status === '97$cancelled' || status === '98$absent') return 'red';
      // else return 'green'
    },

    goTo(id) {
      this.$router.push({ name: 'Chat', params: { id } }).catch(() => {});
    },

    async reload() {
      try {
        this.list = await crudList(api, 'im/activities', {
          sort: { created_at: -1 },
          populate: ['created_by'],
          page: this.page,
          search: this.search,
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
  computed: {
    searchVal: {
      get() {
        return this.search;
      },
      set(val) {
        this.search = val;
        this.page = 1;
        this.reload();
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.activityManagement ::v-deep .v-data-table {
  border: 6px solid var(--v-borderColor-base);
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  font-size: 20px;
}

.activityManagement ::v-deep .v-data-table-header {
  background-image: linear-gradient(to right, rgb(58, 196, 251) 25%, rgb(3, 139, 249));
  font-size: 2rem !important;
  text-align: center;
}

.activityManagement .v-data-table::v-deep th {
  font-size: 20px !important;
}
.activityManagement .v-data-table::v-deep td {
  font-size: 12px !important;
}

.activityManagement ::v-deep .v-data-table.row-height-50 td {
  height: 30px;
}
.activityManagement ::v-deep td.text-center {
  border: 1px solid rgb(55, 158, 250);
}

::v-deep .v-input__slot {
  width: 200px !important;
  min-height: 40px !important;
  padding-bottom: 0 !important;
}
</style>
